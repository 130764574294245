define('stickyCta', function() {
  
  class stickyCta {
    constructor(element) {
      this.container = element;
      this.element = element.querySelector('.text-block-container');
      this.cWidth = this.getStyle(this.container, 'width');
      this.originalPos = this.getStyle(this.element, 'position');
      this.originalBottom = this.getStyle(this.element, 'bottom');
      this.originalWidth = this.getStyle(this.element, 'width');

      this.windowChange();
      window.addEventListener('scroll', () => this.windowChange());
      window.addEventListener('resize', () => this.windowChange());
    }

    windowChange() {
      const verticalOffset = this.findTotalOffset(this.container);
      const scroll = window.pageYOffset + window.innerHeight;
      const cTop = verticalOffset + this.element.clientHeight;
      const cBottom = verticalOffset + this.container.clientHeight;

      if (scroll >= cTop && cBottom >= scroll) {
        this.element.style.position = 'fixed';
        this.element.style.top = '';
        this.element.style.bottom = '0px';
        this.element.style.width = this.cWidth;
      } else if (scroll <= cTop) {
        this.element.style.position = 'absolute';
        this.element.style.bottom = '';
        this.element.style.top = '0px';
        this.element.width = this.cWidth;
      } else if (scroll >= cBottom) {
        this.element.style.position = this.originalPos;
        this.element.style.bottom = this.originalBottom;
        this.element.style.width = this.originalWidth;
      }
    }

    getStyle(element, property) {
      return window.getComputedStyle(element, null).getPropertyValue(property);
    }

    findTotalOffset(element) {
      let offset = 0;

      while (element.offsetParent) {
        if (!isNaN(element.offsetTop)) {
          offset += element.offsetTop;
        }
        element = element.offsetParent;
      }

      return offset;
    }
  }

  const elements = document.querySelectorAll('[data-component="stickyCta"]');

  for (let i = 0, length = elements.length; i < length; i++) {
    new stickyCta(elements[i]);
  }

  return stickyCta;
});
